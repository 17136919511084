.desktop-layout .editorial-font {
  font-family: "EditorialNew";
  color: var(--eerie-black);
  letter-spacing: -1px;
}

.desktop-layout .body-font {
  font-family: monospace;
  color: var(--dim-gray);
}

.desktop-layout {
  width: 100vw;
  height: 100vh;
  position: fixed;
}

.desktop-layout .home-content {
  height: 100vh;
  position: relative;
}

.desktop-layout .home-content-name,
.desktop-layout .home-content-title {
  font-size: calc(6vw + 6vh);
  line-height: calc(6vw + 6vh);
  position: absolute;
  z-index: 2;
  padding: 2vw;
  padding-top: 3vw;
  max-width: 100px;
}

.desktop-layout .home-content-name {
  transform-origin: top;
  z-index: 3;
}

.desktop-layout .home-content-title {
  bottom: 0;
  transform-origin: bottom;
}

.desktop-layout .home-content-subtitle {
  width: 26vw;
  height: 56dvh;
  object-fit: cover;
  top: 107%;
  right: 50%;
  transform: translateY(-50%) translateX(50%);
  filter: grayscale();
  text-align: right;
  font-size: var(--font-sub-md);
  font-family: monospace;
  position: absolute;
}

.desktop-layout .home-content-img {
  position: absolute;
  width: 26vw;
  height: 56dvh;
  object-fit: cover;
  top: 50%;
  right: 50%;
  transform: translateY(-50%) translateX(50%);
  filter: grayscale();
}

.desktop-layout .tab {
  height: calc(100% - 4vw);
  width: 100vw;
  border-left: 0.15vw solid var(--eerie-black);
  position: absolute;
  overflow: hidden;
  background: rgba(241, 232, 231, 0.95);
  backdrop-filter: blur(4px);
  top: 2vw;
}

.desktop-layout .tab.smooth-transition {
  transition: transform 300ms ease-out;
}

.desktop-layout #paradox {
  left: calc(100% - 32%);
  z-index: 3;
}

.desktop-layout #breakup {
  left: calc(100% - 24%);
  z-index: 4;
}

.desktop-layout #approach {
  left: calc(100% - 16%);
  z-index: 5;
}

.desktop-layout #ready {
  left: calc(100% - 8%);
  z-index: 6;
}

.desktop-layout .tab-content {
  position: relative;
  width: 100%;
  height: 100%;
}

.desktop-layout .tab .title-text {
  position: absolute;
  top: 92%;
  transform: rotate(-90deg);
  transform-origin: left;
  font-size: calc(3vh + 3vw);
  left: calc(3vh + 3vw);
  cursor: pointer;
  width: 100%;
  padding-block: 16px;
  user-select: none;
}

.desktop-layout .tab .body-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 10vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  transition: opacity 300ms ease-out;
  overflow-y: auto;
}

.desktop-layout .tab#paradox .body-container {
  width: 50%;
  margin-left: 5.5%;
}

.desktop-layout .tab#breakup .body-container {
  width: 50%;
  margin-left: 10%;
}

.desktop-layout .tab#approach .body-container {
  width: 50%;
  margin-left: 13%;
}

.desktop-layout .tab#ready .body-container {
  width: 85%;
}

.desktop-layout .tab .body-container .body-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--eerie-black);
  width: 100%;
  padding-bottom: 8vw;
  font-size: var(--font-sm);
  line-height: var(--line-height-sm);
}

.desktop-layout .tab#ready .body-content {
  padding-bottom: 0;
}

.desktop-layout .ready-body-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.desktop-layout .body-content-row {
  display: block;
  column-count: 2;
  column-gap: 4vw;
  width: 100%;
}

.desktop-layout .body-content-row.top-margin {
  margin-top: 1.5vw;
}

.desktop-layout .body-content-row.single-column {
  column-count: 1;
}

.desktop-layout .body-content-column {
  flex: 1;
}

.desktop-layout .body-content-section {
  margin-top: 1.5vw;
  break-inside: avoid-column;
}

.desktop-layout .body-content-section:first-child {
  margin-top: 0;
}

.desktop-layout .body-content-section.auto-wrap {
  break-inside: auto;
}

.desktop-layout .body-content-section.big-font {
  font-size: var(--font-lg);
  line-height: var(--line-height-lg);
}

.desktop-layout .approach-contents .body-content-section {
  margin-top: 2vw;
}

.desktop-layout .approach-contents .body-content-section.no-top-margin {
  margin-top: 0;
}

.desktop-layout .body-content-section.gray-font {
  color: var(--dim-gray);
}

.desktop-layout .tab .body-container .body-title {
  font-size: var(--font-xl);
  line-height: var(--line-height-xl);
  margin-bottom: var(--spc);
}

.desktop-layout .tab .body-container .ready-body-title {
  font-size: var(--font-ready-xl);
  line-height: var(--line-height-ready-xl);
  margin-bottom: var(--spc);
}

.desktop-layout .tab .body-container .body-subtitle {
  font-size: 1.05vw;
  letter-spacing: 0.06vw;
  text-transform: uppercase;
  max-width: 17vw;
  text-align: center;
}

.desktop-layout .tab .body-container .body-cta {
  border: 0.1vw solid var(--eerie-black);
  border-radius: 2.5vw;
  padding-inline: 3vw;
  padding-top: 0.4vw;
  font-size: var(--font-xl);
  text-align: center;
  background-color: var(--off-white);
  transition: background-color 300ms ease-out;
  cursor: pointer;
  margin-bottom: 0.1vw;
  position: fixed;
  bottom: 1vw;
}

.desktop-layout .tab .body-container .body-cta:hover {
  border: 0.1vw solid var(--off-white);
  background-color: var(--eerie-black);
  color: var(--off-white);
  cursor: pointer;
}

.desktop-layout .ready-container {
  display: flex;
  height: 100%;
}

.desktop-layout .ready-container .ready-content {
  flex: 4;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 24px;
  align-items: center;
  padding-right: 60px;
  text-align: center;
  margin-bottom: 9vw;
}

.desktop-layout .ready-container .testimonial-content {
  flex: 6;
  border-left: 0.15vw solid var(--eerie-black);
  position: relative;
  padding-top: 6vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.desktop-layout .current-testimonial {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 65%;
  transition: opacity 300ms ease-out;
  animation: fadeIn 300ms forwards ease-out;
}

.desktop-layout .current-testimonial.fading {
  animation: fadeOut 300ms forwards ease-out;
}

.desktop-layout .current-testimonial-content {
  text-align: center;
  margin-bottom: 2vw;
}

.desktop-layout .testimonial-counter {
  color: var(--dim-gray);
  margin-bottom: 2vw;
  display: flex;
  align-items: center;
  gap: 0.25vw;
  user-select: none;
  font-size: 1.1vw;
  margin-top: 1vw;
  justify-content: center;
}

.desktop-layout .ready-container .ready-img {
  width: 25vw;
  height: 25vw;
  object-fit: cover;
  object-position: top;
  margin-bottom: 2vw;
}

.desktop-layout .butterfly {
  width: 25vw;
  height: 25vw;
  object-fit: cover;
  margin-bottom: 2vw;
  filter: grayscale();
  transition: filter 600ms ease-out;
}

.desktop-layout .butterfly:hover {
  filter: none;
}

.desktop-layout .testimonial-arrow {
  height: 1.25vw;
  width: 1.25vw;
  border-radius: 50%;
  cursor: pointer;
  fill: var(--auburn);
}

.desktop-layout .testimonial-arrow-taptarget {
  width: 1.5vw;
  height: 1.5vw;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.desktop-layout .testimonial-arrow-taptarget.target-right {
  justify-content: flex-start;
}


.desktop-layout .testimonial-arrow:hover {
  fill: var(--dim-gray);
}

.desktop-layout .approach-header {
  display: flex;
  align-items: flex-start;
  gap: 2vw;
}

.desktop-layout .approach-img {
  width: 15vw;
  object-fit: cover;
  object-position: top;
  aspect-ratio: 7 / 8;
}

.desktop-layout .approach-header-text {
  font-size: var(--font-md);
  line-height: var(--line-height-md);
}

.desktop-layout .testimonials-and-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-left: calc(4.5vw + 4.5vh);
  height: 100%;
}

.desktop-layout .testimonials {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.desktop-layout .links-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.desktop-layout .social-link {
  line-height: 2.2vw;
  cursor: pointer;
  text-decoration: none;
}

.desktop-layout .social-link:hover {
  color: var(--dim-gray);
}

.desktop-layout .policy-link {
  line-height: 1.2vw;
  cursor: pointer;
}

.desktop-layout .policy-link:hover {
  color: var(--eerie-black);
}

.desktop-layout .hide-on-desktop {
  display: none !important;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.very-small-text {
  font-size: calc(var(--spc) * 1.25);
}