:root {
  /* body text */
  --font-sm: 14px; 
  --line-height-sm: 22px;

  /* subtitle text */
  --font-sub-md: 16px;
  --line-height-sub-md: 20px;

  /* red text */
  --font-md: 20px;
  --line-height-md: 30px;

  /* bigger body text */
  --font-lg: 24px;
  --line-height-lg: 32px;

  /* section header, CTA text */
  --font-xl: 40px;
  --line-height-xl: 52px;

  /* ready title and links */
  --font-ready-xl: 40px;
  --line-height-ready-xl: 40px;

  /* tab title, modal header */
  --font-2xl: 80px;
  --line-height-2xl: 102px;

  /* home name & title */
  --font-3xl: 170px;
  --line-height-3xl: 216px;

  /* spacing unit */
  --spc: 8px;
}

/* QHD */
@media (min-width: 2000px) {
  :root {
    /* body text */
    --font-sm: 21px; 
    --line-height-sm: 33px;

    /* subtitle text */
    --font-sub-md: 24px;
    --line-height-sub-md: 30px;

    /* red text */
    --font-md: 30px;
    --line-height-md: 45px;

    /* bigger body text */
    --font-lg: 36px;
    --line-height-lg: 48px;

    /* section header, CTA text */
    --font-xl: 60px;
    --line-height-xl: 78px;

    /* ready title and links */
    --font-ready-xl: 60px;
    --line-height-ready-xl: 60px;

    /* tab title, modal header */
    --font-2xl: 120px;
    --line-height-2xl: 153px;

    /* home name & title */
    --font-3xl: 255px;
    --line-height-3xl: 324px;

    /* spacing unit */
    --spc: 12px;
  }
}

/* 4k */
@media (min-width: 3200px) {
  :root {
    /* body text */
    --font-sm: 32px; 
    --line-height-sm: 48px;

    /* subtitle text */
    --font-sub-md: 36px;
    --line-height-sub-md: 45px;

    /* red text */
    --font-md: 45px;
    --line-height-md: 64px;

    /* bigger body text */
    --font-lg: 54px;
    --line-height-lg: 72px;

    /* section header, CTA text */
    --font-xl: 90px;
    --line-height-xl: 117px;

    /* ready title and links */
    --font-ready-xl: 90px;
    --line-height-ready-xl: 90px;

    /* tab title, modal header */
    --font-2xl: 180px;
    --line-height-2xl: 225px;

    /* home name & title */
    --font-3xl: 375px;
    --line-height-3xl: 486px;

    /* spacing unit */
    --spc: 18px;
  }
}

/* 5k */
@media (min-width: 4500px) {
  :root {
    /* body text */
    --font-sm: 48px; 
    --line-height-sm: 72px;

    /* subtitle text */
    --font-sub-md: 54px;
    --line-height-sub-md: 68px;

    /* red text */
    --font-md: 68px;
    --line-height-md: 96px;

    /* bigger body text */
    --font-lg: 81px;
    --line-height-lg: 108px;

    /* section header, CTA text */
    --font-xl: 135px;
    --line-height-xl: 175px;

    /* ready title and links */
    --font-ready-xl: 135px;
    --line-height-ready-xl: 135px;

    /* tab title, modal header */
    --font-2xl: 270px;
    --line-height-2xl: 336px;

    /* home name & title */
    --font-3xl: 562px;
    --line-height-3xl: 729px;

    /* spacing unit */
    --spc: 27px;
  }
}

[data-theme="dark"] {
}

* {
  -webkit-tap-highlight-color: transparent;
}

::-moz-selection { /* Code for Firefox */
  background: var(--eerie-black);
  color: var(--off-white);
}

::selection {
  background: var(--eerie-black);
  color: var(--off-white);
}

body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background: var(--off-white);
  overscroll-behavior: none;
}

.desktop-modal {
  width: 100vw;
  height: 100dvh;
  position: fixed;
  background:rgba(241, 232, 231, 0.95);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
  animation: entryAnimation 600ms forwards ease-out;
}

.desktop-modal.exiting {
  animation: exitAnimation 600ms forwards ease-out;
}

@keyframes entryAnimation {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes exitAnimation {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(100%);
  }
}

.desktop-modal-header {
  font-family: "EditorialNew";
  color: var(--eerie-black);
  letter-spacing: -1px;
  position: absolute;
  top: 90%;
  transform: rotate(-90deg);
  transform-origin: left;
  font-size: var(--font-2xl);
  line-height: var(--line-height-2xl);
  left: 0;
  cursor: pointer;
  width: 52%;
  padding-block: calc(2 * var(--spc));
  user-select: none;
  border-top: calc(var(--spc) / 3) solid var(--eerie-black);
  margin-left: calc(var(--spc) * 12)
}

.desktop-modal-button {
  font-family: "EditorialNew";
  position: absolute;
  border: calc(var(--spc) / 6) solid var(--eerie-black);
  border-radius: 2.5vw;
  padding-inline: calc(var(--spc) * 8);
  padding-top: var(--spc);
  font-size: var(--font-xl);
  line-height: var(--line-height-xl);
  text-align: center;
  background-color: var(--off-white);
  transition: background-color 300ms ease-out;
  cursor: pointer;
  margin-bottom: var(--spc);
  bottom: var(--spc);
}

.desktop-modal-button:hover {
  border: calc(var(--spc) / 6) solid var(--off-white);
  background-color: var(--eerie-black);
  color: var(--off-white);
  cursor: pointer;
}

.desktop-modal-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  overflow-y: auto;
  margin: calc(var(--spc) * 5) 0 var(--spc) 0;
  padding-bottom: calc(var(--spc) * 5);
}

.desktop-modal-content {
  font-family: monospace;
  width: 40%;
  padding-bottom: calc(var(--spc) * 5);
  font-size: var(--font-sm);
  line-height: var(--line-height-sm);
}

.desktop-modal .policy-header {
  color: var(--dim-gray);
  text-transform: uppercase;
  margin-bottom: calc(var(--spc) / 2);
}

.desktop-modal .policy-section {
  margin-bottom: calc(var(--spc) * 2);
}

.desktop-modal .policy-section:last-child {
  padding-bottom: calc(var(--spc) * 10);
}

.mobile-modal {
  width: 100vw;
  height: 100dvh;
  position: fixed;
  background:rgba(241, 232, 231, 0.95);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
  display: flex;
  flex-direction: column;
  animation: mobileEntryAnimation 450ms forwards ease-out;
}

.mobile-modal.exiting {
  animation: mobileExitAnimation 450ms forwards ease-out;
}

@keyframes mobileEntryAnimation {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes mobileExitAnimation {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(100%);
  }
}

.mobile-modal-header {
  font-family: "EditorialNew";
  font-size: var(--font-lg);
  line-height: var(--line-height-lg);
  display: flex;
  height: calc(var(--spc) * 6);
  align-items: center;
  padding-top: calc(var(--spc) * 1);
  margin-inline: calc(var(--spc) * 2);
  border-bottom: calc(var(--spc) / 3) solid var(--eerie-black);
}

.mobile-modal-content {
  flex: 1;
  font-family: monospace;
  margin: var(--spc) 0 var(--spc) 0;
  overflow-y: auto;
  font-size: var(--font-sm);
  line-height: var(--line-height-sm);
  padding: 0 calc(var(--spc) * 2) calc(var(--spc) * 10) calc(var(--spc) * 2);
}

.mobile-modal .policy-header {
  color: var(--dim-gray);
  text-transform: uppercase;
  margin-bottom: calc(var(--spc) * 2);
  color: var(--dim-gray);
}

.mobile-modal .policy-section {
  margin-bottom: calc(var(--spc) * 4);
  color: var(--eerie-black);
}

.mobile-modal-button {
  font-family: "EditorialNew";
  font-size: var(--font-lg);
  line-height: var(--line-height-lg);
  position: absolute;
  bottom: 0;
  left: calc(var(--spc) * 2);
  border: calc(var(--spc) / 6) solid var(--eerie-black);
  border-radius: 54px;
  padding: calc(var(--spc) * 1) calc(var(--spc) * 8);
  text-align: center;
  background-color: var(--off-white);
  transition: background-color 300ms ease-out;
  cursor: pointer;
  margin-bottom: calc(var(--spc) * 4);
}

.policy-mail-link {
  color: var(--eerie-black);
}

.policy-mail-link:hover {
  color: var(--dim-gray);
}